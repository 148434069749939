import serviceAxios from "./http";
import axios from 'axios';

export const  getDevice = (data) => serviceAxios({
    url: "/manage/device/list",
    method: "post",
    data,
})
export const  addDevice = (data) => serviceAxios({
    url: "/manage/device/add",
    method: "post",
    data,
})
export const  editDevice = (data) => serviceAxios({
    url: "/manage/device/edit",
    method: "post",
    data,
})
export const  delDevice = (data) => serviceAxios({
    url: "/manage/device/del",
    method: "post",
    data,
})
export const  getUser = (data) => serviceAxios({
    url: "/manage/user/list",
    method: "post",
    data,
})
export const  addUser = (data) => serviceAxios({
    url: "/manage/user/add",
    method: "post",
    data,
})
export const  editUser = (data) => serviceAxios({
    url: "/manage/user/edit",
    method: "post",
    data,
})
export const  delUser = (data) => serviceAxios({
    url: "/manage/user/del",
    method: "post",
    data,
})
export const  getVersion = (data) => serviceAxios({
    url: "/manage/version/list",
    method: "get",
    data,
})
export const  delVersion = (data) => serviceAxios({
    url: "/manage/version/del",
    method: "post",
    data,
})

export const  bandUserDevice = (data) => serviceAxios({
    url: "/manage/userDevice/band",
    method: "post",
    data,
})

export const  queryUserBandList = (data) => serviceAxios({
    url: "/manage/userDevice/bandList",
    method: "post",
    data,
})
   
export const  queryUserDevice = (data) => serviceAxios({
    url: "/manage/userDevice/list",
    method: "post",
    data,
})

export const  login = (data) => serviceAxios({
    url: "/manage/user/loginSysterm",
    method: "post",
    data,
})

export const  getRoomList = (data) => serviceAxios({
    url: "/manage/scene/allList",
    method: "post",
    data,
})

export const  shareRoom = (data) => serviceAxios({
    url: "/manage/scene/share",
    method: "post",
    data,
})



export const  addVersion = (formData) => axios.post('/apis/manage/upload/version', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })